// Import Fonts
@import "external";

$accent-color: #FD5B2E;

// global styles
*, *::before, *::after {
  box-sizing: inherit;
}

div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

body, a, span, div, li, label,
h1, h2, h3, h4, h5, h6,
button, input, textarea, select {
  font-family: "Afacad";
}

body, a, span, div, li, label, input, textarea, select {
  color: #4e4e4e;
}

h1, h2, h3, h4, h5, h6 {
  color: #000000;
  text-rendering: optimizeLegibility;
  margin: 0;
}

body {
  background: linear-gradient(119.36deg, #FFFFFF 14%, #F6F6F6 86%);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-size: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

input {
  &[type="text"],
  &[type="email"],
  &[type="password"],
  &[type="tel"],
  &[type="number"],
  &[type="search"] {
    display: block;
    width: 100%;

    &::placeholder {
      color: #cacaca;
    }
  }
}

textarea {
  display: block;
  width: 100%;

  &::placeholder {
    color: #cacaca;
  }
}

label {
  display: block;

  &.error {
    font-size: 16px !important;
    color: #E2493B !important;
  }
}

button {
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: 0 0;
  line-height: 1;
  cursor: pointer;
}

.wrap {
  width: 90%;
  max-width: 1100px;
  margin: 0 auto;

  &--wide {
    max-width: 1300px;
  }

  &--thin {
    max-width: 946px;
  }
}

.loader {
  border: 3px solid transparent;
  border-top: 3px solid #4e4e4e;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: linear spin 1s infinite;
  -webkit-animation: linear spin 1s infinite;

  &--center {
    margin: 30px auto;
  }
}

.skeleton-item {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  background: #f8f8f8;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, transparent 0%, #f1f1f1 50%, transparent 100%);
    animation: load 1.2s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }
}

@mixin button-base {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 40px;
  border-radius: 8px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.4px;
  width: fit-content;
  max-width: 100%;
  white-space: nowrap;

  &:hover {
    color: #ffffff;
  }

  i, svg {
    width: 18px;
    text-align: center;
    font-size: 18px;
    flex-shrink: 0;
    margin-right: 9px;
  }

  .loader {
    border-top-color: #ffffff;
    width: 20px;
    height: 20px;
  }
}

@mixin button-primary {
  @include button-base;
  background: #FD5B2E;
  color: #ffffff;

  &:hover {
    background: #e34e25;
  }
}

@mixin button-alt {
  @include button-base;
  color: #FD5B2E;
  background: #FFF1ED;

  &:hover {
    color: #FD5B2E;
    background: #FFD9C9;
  }
}

@mixin button-small {
  font-size: 19px;
  margin: 20px auto 0;
}

@mixin button-glow {
  box-shadow: 0 10px 30px 0 #c83d0b59;
}

@mixin button-secondary {
  @include button-base;
  border: 1px solid var(--Accent, $accent-color);
  background: #FFF;
  color: $accent-color;
  box-shadow: 8px 8px 32px 0px rgba(158, 157, 155, 0.16);

  &:hover {
    background-color: #f0f0f0;
    color: $accent-color;
  }
}

.btn {
  @include button-primary;

  &--secondary {
    @include button-secondary;
  }

  &--alt {
    @include button-alt;
  }

  &--margin-top {
    margin-top: 16px;
  }

  &--small {
    @include button-small;
  }

  &--glow {
    @include button-glow;
  }

  &.--force-font-size {
    font-size: 22px !important;
  }
}

.secondary-btn {
  @include button-secondary;
}

.ellipsis {
  position: relative;
  padding-right: 1em;

  &:after {
    overflow: hidden;
    position: absolute;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 2.5s infinite;
    animation: ellipsis steps(4, end) 2.5s infinite;
    content: "...";
    width: 0;
    padding-left: 0.05em;
  }
}

@keyframes load {
  from {
    left: -100%;
  }
  to   {
    left: 100%;
  }
}

@keyframes spin {
  0% {transform: rotate(0deg); }
  100% {transform: rotate(360deg); }
}

@-webkit-keyframes spin {
  0% {transform: rotate(0deg); }
  100% {transform: rotate(360deg); }
}

@keyframes ellipsis {
  to {
    width: 0.9em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1em;
  }
}

.x-fake-monotone {
  --fa-secondary-opacity: 1.0;
}

.grecaptcha-badge {
  visibility: hidden;
}

.overflow-visible {
  overflow: visible !important;
}

.btn {
  display: flex;
  align-items: center;
  padding: 0 71px;
  height: 50px;
  border-radius: 8px;
  background: #FD5B2E;
  color: #ffffff;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.4px;
  width: fit-content;
  max-width: 100%;
  white-space: nowrap;
  justify-content: center;

  &:hover {
    color: #ffffff;
    background: #e34e25;
  }

  &--alt {
    color: #FD5B2E;
    background: #FFF1ED;

    &:hover {
      color: #FD5B2E;
      background: #FFD9C9;
    }
  }

  &--margin-top {
    margin-top: 16px;
  }

  &--margin-bottom {
    margin-bottom: 32px;
  }

  &--small {
    font-size: 19px;
    margin: 20px auto 0;
  }

  &--glow {
    box-shadow: 0 10px 30px 0 #c83d0b59;
  }

  &.--force-font-size {
    font-size: 22px!important;
  }

  &.--discouraged {
    background: #fbe6e2;
    line-height: 44px;
    color: #a8452a;
    min-height: 44px;
    margin: 18px auto 0;
    min-width: 200px;
    width: fit-content;
    padding: 0 16px;
    font-size: 16px;
    box-shadow: none;
    text-decoration: underline;

    &:after {
      content: none;
    }
  }

  &.completed {
    background: #fff url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="%23fd5b2e" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>') no-repeat calc(100% - 12px) center;
    background-size: 25px;
    color: #fd5b2e;
    border: 3px solid #fd5b2e;
    padding-right: 50px;
  }

  img {
    height: 16px;
    margin-left: 12px;

    &.--large {
      height: 22px;
      margin-left: 16px;
    }
  }

  .loader {
    border-top-color: #ffffff;
    width: 20px;
    height: 20px;
  }
}

.cookie-banner {
  position: fixed;
  z-index: 2;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 15px 30px 0 #181818;

  &__content {
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    gap: 16px;

    &>div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
    }

    b {
      font-size: 20px;
      line-height: 20px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      color: #4e4e4e;

      a {
        text-decoration: underline;
      }
    }

    .btn {
      font-size: 16px;
      height: 40px;
      width: 230px;
    }
  }
}

@media (max-width: 700px) {
  .cookie-banner__content {
    flex-direction: column;
    text-align: center;
    gap: 24px;

    &>div {
      align-items: center;
    }

    b {
      font-size: 18px;
      line-height: 18px;
    }
  }
}

div.copied[data-copy] {
  &::after {
    font-family: "Font Awesome 6 Pro";
    content: "\f00c";
    color: #0DA800;
  }
}
