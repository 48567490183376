// Import www styles
@import 'www';

.home-heading {
  min-height: 770px;
  background-color: #ffffff;
  overflow: hidden;

  &>.wrap {
    position: relative;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      top: -240px;
      right: -520px;
      width: 1230px;
      height: 900px;
      background-image: url(/static/images/home-heading-bubble.webp);
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -1;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 640px;
    max-width: 500px;

    &>h1 {
      color: #313131;
      font-size: 64px;
      font-style: normal;
      font-weight: 600;
      line-height: 64px;
      max-width: 400px;
      margin-bottom: 0;
    }

    &>span {
      color: #636363;
      font-size: 20px;
      font-style: normal;
      line-height: 27px;
      margin: 24px 0 30px;
      display: block;
      max-width: 378px;
    }
  }

  &__image {
    position: absolute;
    right: -150px;
    top: calc(50% + 60px);
    transform: translateY(-50%);
    width: 760px;
    height: auto;
    max-width: calc(100% - 330px);

    &--mobile {
      display: none;
    }
  }
}

.home-block {
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    width: 100%;
    height: 55vw;
    background-image: url(/static/images/blob3.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(55%);
    z-index: -1;
  }
}

.sandwich {
  padding: 96px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  &__content {
    max-width: 558px;

    &>span:nth-child(1) {
      color: #FD5B2E;
      font-size: 16px;
      letter-spacing: 0.8px;
      font-weight: 500;
      text-transform: uppercase;
    }

    &>h2 {
      color: #313131;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      margin: 16px 0 24px;
      letter-spacing: 0.48px;
    }

    &>span:nth-child(3) {
      color: #636363;
      font-size: 18px;
      line-height: 24px;
      max-width: 458px;
      display: block;
      letter-spacing: 0.36px;
    }
  }

  &__image {
    width: 40%;
  }
}

.list-features {
  padding-bottom: 64px;

  &>h2 {
    text-align: center;
    max-width: 542px;
    margin: 0 auto 72px;
    color: #313131;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.48px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>div>img {
      display: none
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item {
    padding: 24px;
    border-left: 2px solid #DADADA;
    transition: all 0.5s ease;

    &.active {
      border-left-color: #FD5B2E;
    }

    &>p {
      color: #313131;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.24px;
      margin-bottom: 5px;
    }

    &>span {
      color: #636363;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.32px;
    }
  }

  &__images {
    width: 40%;

    &>img {
      width: 100%;
      display: none;

      &.active {
        display: block;
        box-shadow: 0 50px 30px -50px #bbbbbb;
      }
    }
  }
}

.peace-of-mind {
  min-height: 426px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #FFF1ED;

  &__content {
    display: flex;
    justify-content: start;
    gap: 50px;

    &>img {
      width: 606px;
      max-width: 60%;
      display: flex;
      justify-content: center;
    }

    &>div {
      min-height: 365px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &>h2 {
        color: #313131;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        max-width: 315px;
      }

      &>span {
        color: rgba(0, 0, 0, 0.6);
        font-size: 16px;
        font-style: normal;
        line-height: 21px;
        letter-spacing: 0.32px;
        display: block;
        margin: 18px 0 30px;
        max-width: 273px;
      }

      &>ul {
        list-style: none;
        margin: 0;

        &>li {
          color: #313131;
          font-size: 16px;
          display: flex;

          &:nth-child(2) {
            margin: 16px 0;
          }

          &>img {
            width: 24px;
            margin-right: 16px;
          }
        }
      }
    }
  }
}

.price {
  padding: 96px 0;

  &>h2 {
    color: #313131;
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.48px;
    margin: 0;
  }

  &>span {
    color: #636363;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.36px;
    margin-top: 16px;
    margin-bottom: 30px;
    text-align: center;
    display: block;
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: fit-content;
    margin: 48px auto 32px;

    &>span {
      color: #313131;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.36px;
    }

    &>div {
      display: inline-block;
      width: 64px;
      background: #E6E6E6;
      height: 32px;
      border-radius: 16px;
      position: relative;
      cursor: pointer;
      margin: 0 16px;

      &.active {
        background: #FD5B2E;

        &::before {
          left: 36px;
        }
      }

      &::before {
        content: "";
        background: #fff;
        width: 24px;
        height: 24px;
        position: absolute;
        border-radius: 50%;
        top: 4px;
        left: 4px;
        transition: 0.3s;
      }
    }

    &>img {
      width: 102px;
      position: absolute;
      top: -20px;
      right: -115px;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    width: calc((100% - 48px) / 3);
    padding: 32px 0;
    min-height: 579px;
    position: relative;
    align-self: stretch;
    border-radius: 24px;
    border: 1px solid #E6E6E6;
    box-shadow: 8px 8px 32px 0 rgba(158, 157, 155, 0.16);
    display: flex;
    flex-direction: column;
    background: #ffffff;

    &:nth-child(2) {
      border: 2px solid #FD5B2E;
    }

    &__header {
      display: flex;
      align-items: center;
      padding: 0 32px;

      &>img {
        width: 36px;
        margin-right: 16px;
      }

      &>p {
        color: #313131;
        font-size: 28px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0.56px;
        margin: 0;
      }
    }

    &>span {
      margin: 8px 0 24px;
      color: #636363;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.32px;
      display: block;
      padding: 0 32px;
    }

    &__price {
      color: #636363;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.32px;
      height: 60px;
      padding: 0 32px;
      margin: 0;

      &>span {
        color: #313131;
        font-size: 28px;
        font-weight: 600;
        line-height: 28px;
      }
    }

    &__body {
      padding: 32px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      flex-grow: 1;

      &>p {
        color: #636363;
        font-size: 20px;
        margin-bottom: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.4px;
      }

      &>div {
        display: flex;
        align-items: center;
        margin-top: 16px;

        &>img {
          width: 24px;
          margin-right: 12px;
        }

        &>span {
          color: #313131;
          font-size: 16px;
          letter-spacing: 0.32px;
        }
      }
    }

    &__popular {
      position: absolute;
      right: 32px;
      top: 32px;
      border-radius: 56px;
      border: 1px solid #FD5B2E;
      width: 98px;
      color: #FD5B2E;
      text-align: center;
      font-size: 12px;
      line-height: 26px;
      letter-spacing: 0.24px;
    }

    &__footer {
      padding: 32px 32px 0px 32px;

      &>a {
        width: 100%;
        border: 1px solid #FD5B2E;
        color: #FD5B2E;
        background: #fff;
        font-size: 20px;
        letter-spacing: 0.4px;
      }

      &.active {
        a {
          background: #FD5B2E;
          color: #fff;
        }
      }
    }
  }
}

.comments {
  padding-bottom: 96px;

  h2 {
    color: #313131;
    text-align: center;
    font-size: 48px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.48px;
    margin: 0;
  }

  & > .wrap span {
    color: #636363;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.36px;
    margin-top: 16px;
    margin-bottom: 30px;
    text-align: center;
    display: block;
  }

  &__content {
    margin: 48px 0;
    position: relative;
    overflow-x: clip;

    &__line {
      width: 100%;
      display: flex;
      position: relative;
      justify-content: end;
      align-items: center;
      gap: 24px;
      margin-bottom: 24px;

      &:nth-child(2) {
        margin-bottom: 0;
        transform: translateX(552px);
      }
    }

    &__item {
      padding: 32px;
      min-width: 368px;
      min-height: 266px;
      border-radius: 24px;
      position: relative;
      border: 1px solid #E6E6E6;
      box-shadow: 8px 8px 32px 0 rgba(158, 157, 155, 0.16);
      background: #ffffff;

      &>a {
        position: absolute;
        width: 24px;
        bottom: 16px;
        right: 16px;
        border-radius: 12px;

        &.--text {
          width: 58px;
          font-size: 14px;
          text-decoration: underline;
        }
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > img {
          width: 64px;
          border-radius: 32px;
        }
      }

      &__meta {
        display: flex;
        gap: 12px;

        span {
          color: #313131;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 27px;
          letter-spacing: 0.4px;
        }

        img {
          width: 136px;
          height: 24px;
        }
      }

      &__rate {
        display: flex;
        align-items: center;
        gap: 4px;

        img {
          width: 24px;
          margin: 0;
        }
      }

      p {
        color: #636363;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.36px;
        margin: 16px 0 0;
      }
    }

    &:before, &:after {
      content: "";
      width: 512px;
      height: 100%;
      background: linear-gradient(90deg, #FCFCFC 0%, rgba(255, 255, 255, 0) 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &:after {
      left: auto;
      right: 0;
      background: linear-gradient(270deg, #FCFCFC 0%, rgba(255, 255, 255, 0) 100%);
    }
  }

  &>a {
    margin: 40px auto 0px;
  }
}

.mission {
  display: flex;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #E6E6E6;
  box-shadow: 8px 8px 32px 0 rgba(158, 157, 155, 0.16);
  overflow: hidden;
  height: 400px;
  background: #ffffff;

  &>img {
    width: 57%;
    height: 100%;
  }

  &__content {
    flex-grow: 1;
    width: 0px;
    padding: 40px 56px;

    label {
      margin-bottom: 16px;
      color: #FD5B2E;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.8px;
      text-transform: uppercase;
    }

    p,
    b {
      color: #636363;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.4px;
      margin: 0;
    }

    b {
      margin-top: 30px;
      display: block;
      font-weight: 600;
    }
  }
}

.order-tag {
  background-image: url("/static/images/new-tags-order-bg.webp");
  background-size: auto 291px;
  background-position-x: 100%;
  background-repeat: no-repeat;
  padding: 56px;
  margin-top: 96px;
  border-radius: 24px;
  box-shadow: 8px 8px 32px 0 rgba(158, 157, 155, 0.16);
  border: 1px solid #E6E6E6;

  h2 {
    color: #313131;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.48px;
    max-width: 439px;
  }

  img {
    display: none;
    max-width: 280px;
    width: 90%;
    margin: 32px auto 0;
  }

  a {
    margin-top: 32px;
    font-size: 20px;
    padding: 0 17px;
  }
}

@media only screen and (max-width: 1200px) {
  .peace-of-mind {
    background-position-x: 40%;
  }
}

@media only screen and (max-width: 1100px) {
  .home-heading {
    min-height: auto;

    &__image {
      display: none;

      &--mobile {
        display: block;
        width: 550px;
        right: -30px;
        max-width: calc(100% - 400px);
      }
    }
  }

  .mission {
    &__content {
      padding: 40px 36px;
    }
  }

  .price__item__popular {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .order-tag {
    padding: 40px;
    background-size: auto 260px;
  }
}

@media only screen and (max-width: 900px) {
  .home-heading {
    background: none;

    .wrap {
      &:after {
        top: auto;
        bottom: 0;
        right: -30%;
        width: 140%;
        height: 92vw;
        transform: scaleX(-1);
      }
    }

    &__content {
      display: block;
      min-height: max-content;
      margin: 40px auto;
      text-align: center;
      max-width: max-content;

      span {
        max-width: 400px;
      }

      .btn {
        margin: 0 auto;
      }
    }

    &__image {
      &--mobile {
        position: static;
        width: 450px;
        margin: 0 auto;
        max-width: 100%;
        transform: none;
        min-height: 252px;
      }
    }
  }

  .sandwich {
    padding: 48px 0;
    flex-direction: column;
    gap: 40px;
    max-width: 450px;
    margin: 0 auto;

    &__content {
      max-width: 100%;

      &>span:nth-child(1) {
        font-size: 14px;
        letter-spacing: 0.7px;
      }

      h2 {
        font-size: 32px;
        line-height: 32px;
      }

      &>span:nth-child(3) {
        font-size: 16px;
      }
    }

    &__image {
      width: 100%;
      height: auto;
      transform: none;
      box-shadow: 4px 4px 16px #e9e9e9;
      border-radius: 5.2%;
    }
  }

  .list-features {
    padding: 48px 0;
    max-width: 450px;
    margin: 0 auto;

    .features_list {
      min-width: 100%;
    }

    h2 {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 40px;
    }

    &__item {
      padding: 16px 20px;

      p {
        font-size: 16px;
        letter-spacing: 0.32px;
      }
    }

    &__images {
      &>img {
        display: none;

        &.active {
          display: none;
        }
      }
    }

    &__content {
      &>div.features_list>img.active {
        display: block;
        width: 100%;
        max-width: 350px;
        margin: 0 auto 16px;
        box-shadow: 0 30px 16px -20px #e9e9e9;
        border-radius: 5.2%;
      }
    }
  }

  .peace-of-mind {
    padding: 40px 0;

    &__content {
      display: grid;
      gap: 0;
      max-width: 450px;

      &>img {
        width: 606px;
        display: flex;
        order: 2;
        justify-content: center;
        margin-top: 30px;
        max-width: 100%;
      }

      &>div {
        display: block;
        padding: 0;
        min-height: fit-content;

        h2 {
          max-width: 205px;
        }
      }
    }
  }

  .price {
    &__item {
      width: calc((100% - 24px) / 3);
    }
  }

  .comments {
    & > h2 {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 24px;
    }

    & > span {
      font-size: 16px;
    }

    &__content {
      margin-top: 40px;

      &:before, &:after {
        content: none;
      }

      &__line {
        gap: 8px;
        margin-bottom: 8px;
        transform: translateX(calc(578px - 50vw));

        &:nth-child(2) {
          transform: translateX(calc(722.5px - 50vw));
        }
      }

      &__item {
        padding: 16px;
        min-width: 285px;
        min-height: 189px;
        border-radius: 16px;

        & > a {
          width: 19px;
          bottom: auto;
          right: 16px;
          top: 16px;
        }

        &__header {
          justify-content: flex-start;
          gap: 12px;

          & > img {
            width: 48px;
          }
        }

        &__meta {
          display: flex;
          flex-direction: column;
          gap: 2px;

          span {
            order: 2;
            font-size: 16px;
            line-height: 21px;
          }

          img {
            order: 1;
            width: 106px;
            height: 18px;
          }
        }

        p {
          color: #636363;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.28px;
          margin-top: 12px;
        }
      }
    }
  }

  .mission {
    height: fit-content;
    display: block;

    &>img {
      width: 100%;
    }

    &__content {
      width: 100%;
    }
  }

  .order-tag {
    background-size: auto 244px;

    h2 {
      font-size: 40px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .home-heading {
    & > .wrap {
      min-height: 650px;
    }

    &__content {
      text-align: left;
      margin-top: 24px;

      & > h1 {
        font-size: 48px;
        max-width: 327px;
        line-height: 48px;
        letter-spacing: 0.48px;
      }

      & > span {
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.32px;
        max-width: 327px;
      }

      & > .btn {
        width: 100%;
        font-size: 16px;
        height: 45px;

        &.--large {
          font-size: 22px;
          height: 50px;
        }
      }
    }
  }

  .home-block {
    &:after {
      width: 240%;
      height: 130vw;
      left: -82%;
      bottom: -60px;
      transform: translateY(55%);
    }
  }

  .price {
    padding: 48px 0;

    &>h2 {
      font-size: 32px;
    }

    &>span {
      font-size: 16px;
    }

    &__toggle {
      img {
        display: none;
      }

      & > div {
        width: 48px;
        height: 26px;

        &::before {
          width: 18px;
          height: 18px;
        }

        &.active {
          &::before {
            left: 26px;
          }
        }
      }
    }

    &__list {
      margin-top: 24px;
      flex-direction: column;
      gap: 24px 0px;
    }

    &__item {
      width: 100%;
      padding: 16px 0px;
      min-height: auto;
      border-radius: 16px;

      &__header {
        padding: 0 16px;

        &>p {
          font-size: 24px;
        }
      }

      &>span {
        margin: 8px 0px 16px;
        padding: 0 16px;
      }

      &__price {
        padding: 0 16px;
        height: 44px;
      }

      &__body {
        padding: 16px;
        height: auto;
      }

      &__footer {
        padding: 16px 16px 0px 16px;

        &>a {
          font-size: 16px;
        }
      }

      &__popular {
        top: 30px;
        right: 16px;
        display: block;
      }

      &:nth-child(2) {
        border-width: 1px;
      }
    }
  }

  .comments {
    padding: 48px 0;

    & > .wrap {
      text-align: center;
    }

    h2 {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 24px;
    }

    span {
      max-width: 100%;
      font-size: 16px;
    }

    &__content {
      justify-content: center;
      gap: 10px;

      &.second {
        margin-top: 10px;
      }
    }

    &>a {
      margin: 30px auto 0px;
    }
  }

  .mission {
    flex-direction: column;

    &>img {
      height: auto;
      width: 100%;
    }

    &__content {
      padding: 16px;
      width: 100%;

      label {
        font-size: 14px;
        margin-bottom: 8px;
      }

      p,
      b {
        font-size: 16px;
      }

      b {
        margin-top: 16px;
      }
    }
  }

  .order-tag {
    margin-top: 48px;
    border-radius: 24px;
    min-height: auto;
    border: none;
    background-image: url(/static/images/home-heading-bubble.webp);
    background-size: 140%;
    background-position: 20% -50vw;
    background-repeat: no-repeat;
    background-color: #fff;
    text-align: center;

    h2 {
      font-size: 32px;
      max-width: 350px;
      margin: 0 auto;
    }

    img {
      display: block;
    }

    a {
      width: 100%;
      max-width: 320px;
      margin: 24px auto 0;
    }
  }
}

@media only screen and (max-width: 450px) {
  .price {
    &__toggle > span {
      font-size: 16px;
    }
  }

  .order-tag {
    background-position-y: -30vw;
  }
}
