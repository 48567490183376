// fonts
@font-face {
  font-family: "Afacad";
  src: url("/static/fonts/Afacad-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Afacad";
  src: url("/static/fonts/Afacad-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Afacad";
  src: url("/static/fonts/Afacad-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Afacad";
  src: url("/static/fonts/Afacad-Regular.ttf");
  font-weight: 400;
}
