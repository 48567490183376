.bottom-footer {
  padding: 64px 0px 96px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;

    img {
      height: 42px;
    }

    div {
      display: flex;
      gap: 20px;

      img {
        height: 40px;
        width: 40px;
      }
    }
  }

  &__right {
    display: flex;
    align-items: flex-start;
    gap: 110px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 16px;

      p {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        margin: 0px;
      }

      a {
        color: rgba(0, 0, 0, 0.60);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .bottom-footer {
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;

    &.block {
      padding: 48px 24px !important;
    }

    &__left {
      gap: 32px;

      &>a {
        &>img {
          height: 24px;
        }
      }
    }

    &__right {
      flex-wrap: wrap;
      gap: 0px;
      width: 100%;
      justify-content: space-between;
    }
  }
}
