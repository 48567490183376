.navbar {
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 88px;
    padding: 20px 0;

    &__left {
      img {
        height: 30px;
      }
    }

    &__center {
      display: flex;
      align-items: center;
      margin: 0;
      gap: 48px;

      li:first-child {
        display: none;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 24px;

      li a {
        font-weight: 500;
      }

      & > .btn {
        font-size: 18px;
        font-weight: 600;
        padding: 12px 16px;
        letter-spacing: 0.36px;
      }
    }
  }

  &__hamburger {
    display: none;
  }

  &__mobile {
    display: none;
    position: fixed;
    top: 72px;
    left: 0;
    width: 100%;
    height: calc(100% - 72px);
    background: #fff;
    z-index: 999;
    padding: 46px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    &__content {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      flex-direction: column;
    }

    &__top {
      display: flex;
      flex-direction: column;
      gap: 10px;

      a {
        color: #000;
        padding: 12px 16px;
        display: block;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      gap: 8px;

      a {
        width: 100%;
        text-align: center;
        font-size: 16px;
        height: 45px;
      }
    }
  }

  li {
    list-style: none;

    a {
      color: #313131;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.36px;

      &:hover {
        color: #000;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    &__content {
      height: 72px;
      padding: 16px 0;

      &__left {
        img {
          height: 24px;
        }
      }

      &__center, &__right {
        display: none;
      }
    }

    &__hamburger {
      display: block;
      width: 20px;
      cursor: pointer;
      height: 14px;
      padding: 14px 0;

      &__icon {
        height: 2px;
        background: #475467;
        transition: all .5s ease;
        width: 100%;
        position: relative;
        border-radius: 3px;

        &::before {
          position: absolute;
          content: "";
          height: 2px;
          width: 100%;
          background: #475467;
          transition: all .5s ease;
          border-radius: 3px;
          top: -6px;
        }

        &::after {
          position: absolute;
          content: "";
          height: 2px;
          width: 100%;
          background: #475467;
          transition: all .5s ease;
          border-radius: 3px;
          top: 6px;
        }
      }
    }

    &__hamburger.active {
      div {
        height: 0px;

        &::before {
          top: 0px;
          transform: rotate(45deg);
        }

        &::after {
          top: 0px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
